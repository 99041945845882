import React from 'react';
import ReactDOM from 'react-dom/client';
import i18n from "./i18n";

// import './style.scss';
import App, { tutorial_steps } from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';

// react redux
import { Provider, useSelector,  } from 'react-redux';
import store, {persistor} from './store';
import { PersistGate } from 'redux-persist/integration/react';

import track from 'react-tracking';
import { SERVICES } from './utils/ConstantSettings';

import { deviceType, browserName, browserVersion, deviceDetect  } from 'react-device-detect';
import { ValueTester } from './utils/UtilityFunctions';
import { TourProvider } from '@reactour/tour';

// Check the language from localStorage
const lang = localStorage.getItem('i18nextLng')?.toString();

// Force reload on tab close or refresh
window.addEventListener('beforeunload', function() {
  // Force reload on tab close or refresh
  window.location.reload(true);
});

// Clear cache when tab becomes visible again
document.addEventListener('visibilitychange', function() {
  if (document.visibilityState === 'visible') {
    i18n.reloadResources();
  }
});

function detectOS() {
  const platform = navigator.platform;
  if (platform.indexOf('Win') !== -1) return 'Windows';
  if (platform.indexOf('Mac') !== -1) return 'Mac OS';
  if (platform.indexOf('Linux') !== -1) return 'Linux';
  if (platform.indexOf('iPhone') !== -1) return 'iOS';
  if (platform.indexOf('Android') !== -1) return 'Android';
  if (platform.indexOf('iPad') !== -1) return 'iPad';
  return 'Unknown';
}


const TourWrapper=()=>{
  const tutorialSteps = useSelector((state) => state.interactiveTutorialSteps);
  return(
  
      

  <TourProvider steps={tutorialSteps?.steps || []}
                    key={tutorialSteps?.id}   > 
     
  </TourProvider>
  
   

  )
}

if( localStorage.getItem('tutorial_steps') ==='undefined' || localStorage.getItem('tutorial_steps') ==='null'  ){
  localStorage.setItem('tutorial_steps',JSON.stringify(tutorial_steps));
}



// Dynamically import the appropriate style file
if (lang === 'ar') {
  import('./style-ar.scss').then(() => {
    //console.log('Arabic styles loaded');
  }).catch(error => console.error('Error loading Arabic styles:', error));
} else {
  import('./style.scss').then(() => {
    //console.log('Default styles loaded');
  }).catch(error => console.error('Error loading default styles:', error));
}


function filterNullProps(obj) {
    const filteredObj = {};
  
    for (const key in obj) {
      if (obj[key] !== null) {
        filteredObj[key] = obj[key];
      }
    }
  
    return filteredObj;
  }

  const headers = new Headers({
    'Accept': 'application/json; charset=UTF-8',
    'Authorization': `Bearer ${localStorage.getItem("token")}`,
    'Content-Type': 'application/json; charset=UTF-8',
  });

  const headersUnauth = new Headers({

    'Accept': 'application/json; charset=UTF-8',
    'Content-Type': 'application/json; charset=UTF-8'
  });

  async function sendUserTrackingData(payload) {
    if (ValueTester(payload?.session_id) && ValueTester(payload?.profile_id)){
      try {
        //console.log("Attempting to Send Log")
        const response = await fetch(`${SERVICES.apiBaseUrl}/activity/logActivity`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },        
          body: JSON.stringify({ ...payload }),
          method: "POST",
        });
    
        if (!response.ok) {
          // If the response status is not in the success range (e.g., 200-299)
          throw new Error(`Error: ${response?.status} - ${response?.statusText}`);
        }
    
        // You can return something if needed (e.g., response.json())
      } catch (error) {
        // Handle network errors or other exceptions
        console.error('Error sending data:', error?.message);
        // Optionally rethrow the error if you want to propagate it further
        throw error;
      }      
    }
  }

  async function sendUserTrackingDataUnauth(payload) {
    try {
      //console.log("Attempting to Send Log")
      const response = await fetch(`${SERVICES.apiBaseUrl}/activity/logActivityUnauthenticated`, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ ...payload }),
        method: "POST",
      });
  
      if (!response.ok) {
        // If the response status is not in the success range (e.g., 200-299)
        throw new Error(`Error: ${response?.status} - ${response?.statusText}`);
      }
  
      // You can return something if needed (e.g., response.json())
    } catch (error) {
      // Handle network errors or other exceptions
      console.error('Error sending data:', error?.message);
      // Optionally rethrow the error if you want to propagate it further
      throw error;
    }
  }

  const getUserIp = async () => {
    try {
      const response = await fetch("https://api.ipify.org/?format=json");
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      //console.log(data);
      localStorage.setItem("user_ip", data.ip)

      // Fetch geolocation based on the obtained IP
      const geoResponse = await fetch(`https://ipapi.co/${data.ip}/json/`);
      if (!geoResponse.ok) {
        throw new Error(`Geolocation API request failed! Status: ${geoResponse.status}`);
      }

    const geoData = await geoResponse.json();
    // console.log(geoData); // contains extensive geo location data
    localStorage.setItem("user_geo_data", JSON.stringify({ country: geoData?.country_name, region: geoData?.region, city: geoData?.city, }))

    } catch (error) {
      console.error('Error fetching data:', error.message);
    }
  };



const TrackedApp = track(

    { app: "Simpla" },
    {
      
      dispatch: async (data) => {
      //getting user's public IP:
      
      // Retrieve user information from localStorage
      const user_ip = localStorage.getItem("user_ip")
      const profile_id = localStorage.getItem("profileid");
      const firstname = localStorage.getItem("firstname")
      const lastname = localStorage.getItem("lastname")
      const is_logged_in = localStorage.getItem("profile") === "true";
      const user_token = localStorage.getItem("token");
      const user_geo_data = JSON.parse(localStorage.getItem("user_geo_data"))
      const device_info = JSON.parse(localStorage.getItem("device_info"))
      const session_id = localStorage.getItem("session_id")

      if (localStorage.getItem("user_geo_data") === null){
        getUserIp() // on logout localstorage is cleared
      }

      // device detect requires a library: npm install react-device-detect
      const deviceInfo = {
        device_type: `${deviceType === "browser" ? "Computer" : deviceType}`,
        operating_system: `${detectOS()}`,
        browser_name: `${browserName}`,
        browser_version: `${browserVersion}`
        }
      
      localStorage.setItem('device_info', JSON.stringify(deviceInfo))

      // Include user information in the tracking data
      const userData = filterNullProps({
        ...data,
        profile_id,
        session_id: session_id,
        
        event_data: {

          // contains details of event
          event_info: {
            ...data.event_data   
          },
          
          // contains OS, device, browser info
          device_info: deviceInfo,

          // contains details of the user that performed/caused the event
          user_info: filterNullProps({
            firstname,
            lastname,
            ...user_geo_data,
            user_ip,
            is_logged_in: data?.event_data?.action === "user just logged out" ? false : is_logged_in,
          }),



        }

      });
      
      // uncomment it to log activity of the user in the browser console
      //console.log("Activity Log:", userData); 

      if (session_id && session_id !== null){
      sendUserTrackingData(userData)
        .then((response) => {
          //console.log("Request sent successfully")
        })
        .catch((error) => {
          //console.error("Error sending Request:", error)
      });        
      } 
      else {
        sendUserTrackingDataUnauth(userData)
        .then((response) => {
          //console.log("Request sent successfully")
        })
        .catch((error) => {
          //console.error("Error sending Request:", error)
      });  
      }
      //(window.dataLayer = window.dataLayer || []).push(userData);
      },
    }

  )(App);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
 
  <Provider store={store}>
      
  <PersistGate loading={null} persistor={persistor}>
  
    <TrackedApp />  


  </PersistGate>

  </Provider>
 
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

reportWebVitals();
