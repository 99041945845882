import React, { useState } from "react";
import "../VerticalTabs/verticaltabs.scss";
import { useNavigate, Link } from "react-router-dom";
import { ChatIcon, CommentIcon, ProductTourIcon, VedioIcon } from "../../assets/SVG/svg";
import ModalVedio from "../ModalVedio";
import { useTracking } from "react-tracking";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { disableAllChatMode } from "../../ReduxSlices/ChatModeSlice";
import { useTour } from "@reactour/tour";
import { getTutorialStepsById } from "../../ReduxSlices/InteractiveTutorialSlice";
import { updateuserInfo } from "../../services/api";
import { saveStepProgress, tutorial_steps } from "../../App";
import { setKillRequest } from "../../ReduxSlices/RequestKillerSlice";
const VerticalTabsHelp = (props) => {

 
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();
  const {t} = useTranslation();
  const dispatch=useDispatch();
  const { setIsOpen ,setSteps,setCurrentStep,currentStep} = useTour();
  const requestActive = useSelector((state)=>state.requestActive)
  

  const {trackEvent} = useTracking()

  return (
    <>
      <div className="TabBox">
        <Link className="nav-btn vediobtn tooltip" 
        onClick={()=>{    
            trackEvent({event_type: "click", event_name: `user accessed video tutorial from Help Tab`, event_data: {video_link: "https://www.youtube.com/@SimplaAI/videos"} })
            window.open('https://www.youtube.com/@SimplaAI/videos')}}>
          <div className="tooltipTextWrapper">
            <span class="tooltiptext">{t("tutorial")}</span>
          </div>
          <VedioIcon />
        </Link>
        {show ? <ModalVedio show={show} handleClose={handleClose} /> : null}
        <a
          className="nav-btn chatbtn tooltip"
          href="https://www.simpla.ai/faqs/"
          target="_blank"
          onClick={()=>{
            trackEvent({event_type: "click", event_name: `user clicked on FAQs in Help Tab`, event_data: {faq_link: "https://www.simpla.ai/faqs"} })
          }}
        >
          <div className="tooltipTextWrapper">
            <span class="tooltiptext">{t("faqs")}</span>
          </div>
          <ChatIcon />
        </a>
        <a className="nav-btn tourbtn tourbtn-skipped tooltip" onClick={()=>{ 
             navigate('/');
          dispatch(disableAllChatMode());   localStorage.setItem('tutorial_active','true');setSteps(getTutorialStepsById('chatmode_selection'));setCurrentStep(0)
         
          props?.closeHelp();
          props?.closeHistory();
          console.log(JSON.stringify(tutorial_steps));
        localStorage.setItem('tutorial_steps',JSON.stringify(tutorial_steps));
        saveStepProgress(1);
          var tutorial_state={
            tutorial_steps,
            activate:true,
          }
          var user={
            tutorial_state
          }
          updateuserInfo({user})
     setIsOpen(true);

   
      
      }

        
        
        } onClickCapture={()=>{ 
    
   ;  trackEvent({event_type: "click", event_name: `user clicked on Product Tour in Help Tab`, event_data: {action: "User took an on-site product tour tutorial from the help tab"} })}}>
          <div className="tooltipTextWrapper">
            <span class="tooltiptext" style={{fontSize:"small"}}>{t("product_tour")}</span>
          </div>
          <ProductTourIcon />
        </a>
      </div>
    </>
  );
};
export default VerticalTabsHelp;
