import { create } from "@mui/material/styles/createTransitions";
import { createSlice } from "@reduxjs/toolkit";
import { ValueTester } from "../utils/UtilityFunctions";
import { Box, Typography } from "@mui/material";
import CustomArrow from "../components/CustomArrow";
import { tutorialLocalizer } from "../locales/InteractiveTutorialLocalization/TutorialLocalizer";


// const headerJoyride = (
//     <div className="joyride_header">
//       <label className="form-label"> {t("joyride_guide_me")} </label>
//       <Dropdown>
//         <Dropdown.Toggle id="dropdown-basic" className="bg-transparent border-0">
//         {t("joyride_select_feature")}
//         </Dropdown.Toggle>
  
     
//       </Dropdown>
//     </div>
//   );

const connector = (
    <Box
      sx={{
        position: "absolute",
        bottom: "-50px",
        left: localStorage.getItem("i18nextLng") === "ar" ? "109px" : "160px",
        minHeight: "35px",
        background: "#FF690F",
       border:'1px solid #FF690F'
      }}
    >
        <Box sx={{position: 'absolute',
    bottom: '-10px',
    minWidth: '11px',
    background: '#FF690F',
    borderRadius: '50px',
    minHeight: '11px',
    left: '-5px'}} > 

        </Box>
    </Box>
  );

  
const connector_12 = (
    <Box
      sx={{
        position: "absolute",
        bottom: "-60px",
        left: "160px",
        minHeight: "35px",
        background: "#FF690F",
       border:'1px solid #FF690F'
      }}
    >
        <Box sx={{position: 'absolute',
    bottom: '-10px',
    minWidth: '11px',
    background: '#FF690F',
    borderRadius: '50px',
    minHeight: '11px',
    left: '-5px'}} > 

        </Box>
    </Box>
  );

  const connector_10 = (
    <Box
      sx={{
        position: "absolute",
        transform:'rotate(270deg)',
        bottom: "17px",
        left: localStorage.getItem("i18nextLng") === "ar" ? "" : "427px",
        right: localStorage.getItem("i18nextLng") === "ar" ? "427px" : "",
        minHeight: "35px",
        background: "#FF690F",
       border:'1px solid #FF690F'
      }}
    >
        <Box sx={{position: 'absolute',
    bottom: localStorage.getItem("i18nextLng") === "ar" ? "28px" : "-10px",
    minWidth: '11px',
    background: '#FF690F',
    borderRadius: '50px',
    minHeight: '11px',
    left: '-5px'}} > 

        </Box>
    </Box>
  );

  const connector_15 = (
    <Box
      sx={{
        position: "absolute",
        transform:'rotate(270deg)',
        bottom: "6px",
        left: "407px",
        minHeight: "35px",
        background: "#FF690F",
       border:'1px solid #FF690F'
      }}
    >
        <Box sx={{position: 'absolute',
    bottom: '-10px',
    minWidth: '11px',
    background: '#FF690F',
    borderRadius: '50px',
    minHeight: '11px',
    left: '-5px'}} > 

        </Box>
    </Box>
  );


  const connector_11 = (
    <Box
      sx={{
        position: "absolute",
        transform:'rotate(89.5deg)',
        bottom: "30px",
        left: "-42px",
        minHeight: "38px",
        background: "#FF690F",
       border:'1px solid #FF690F'
      }}
    >
        <Box sx={{position: 'absolute',
    bottom: '-10px',
    minWidth: '11px',
    background: '#FF690F',
    borderRadius: '50px',
    minHeight: '11px',
    left: '-5px'}} > 

        </Box>
    </Box>
  );

  const connector_9 = (
    <Box
      sx={{
        position: "absolute",
        bottom: "-39px",
        left: "222px",
        minHeight: "42px",
        background: "#FF690F",
       border:'1px solid #FF690F'
      }}
    >
        <Box sx={{position: 'absolute',
    bottom: '-10px',
    minWidth: '11px',
    background: '#FF690F',
    borderRadius: '50px',
    minHeight: '11px',
    left: '-5px'}} > 

        </Box>
    </Box>
  );

  const connector_8 = (
    <Box
      sx={{
        position: "absolute",
        bottom: "-31px",
        left: "160px",
        minHeight: "30px",
        background: "#FF690F",
       border:'1px solid #FF690F'
      }}
    >
        <Box sx={{position: 'absolute',
    bottom: '-10px',
    minWidth: '11px',
    background: '#FF690F',
    borderRadius: '50px',
    minHeight: '11px',
    left: '-5px'}} > 

        </Box>
    </Box>
  );

  const connector_6 = (
    <Box
      sx={{
        position: "absolute",
        bottom: "-47px",
        left: "160px",
        minHeight: "35px",
        background: "#FF690F",
       border:'1px solid #FF690F'
      }}
    >
        <Box sx={{position: 'absolute',
    bottom: '-10px',
    minWidth: '11px',
    background: '#FF690F',
    borderRadius: '50px',
    minHeight: '11px',
    left: '-5px'}} > 

        </Box>
    </Box>
  );

  const connector_2 = (
<>
    <Box
        sx={{
          position: "absolute",
          top: "-108px", // Adjusted to align with new child position
          left: localStorage.getItem("i18nextLng") === "ar" ? "" : "154px", 
          right: localStorage.getItem("i18nextLng") === "ar" ? "154px" : "",
          minHeight: "41px", // Adjusted from parent
          background: "#FF690F", // Adjusted from parent
          border: "1px solid #FF690F", // Adjusted from parent
        }}
      />
    <Box
      sx={{
        position: "absolute",
        bottom: "106px", // Adjusted from child
        minWidth: "11px", // Adjusted from child
        background: "#FF690F",
        borderRadius: "50px", // Adjusted from child
        minHeight: "11px", // Adjusted from child
        left: localStorage.getItem("i18nextLng") === "ar" ? "" : "149px",
        right: localStorage.getItem("i18nextLng") === "ar" ? "149px" : "",
      }}
    >
    </Box>
    </>
  );

  const connector_3 = (
    <Box
      sx={{
        position: "absolute",
        bottom: "-32px",
        left: localStorage.getItem("i18nextLng") === "ar" ? "" : "5px",
        right: localStorage.getItem("i18nextLng") === "ar" ? "85px" : "",
        minHeight: "32px",
        background: "#FF690F",
       border:'1px solid #FF690F'
      }}
    >
        <Box sx={{position: 'absolute',
    bottom: '-10px',
    minWidth: '11px',
    background: '#FF690F',
    borderRadius: '50px',
    minHeight: '11px',
    left: '-5px'}} > 

        </Box>
    </Box>
  );
  // history button tutorial - post 1st chat - connector
  const connector_4 = (
    <Box
      sx={{
        position: "absolute",
        left: localStorage.getItem("i18nextLng") === "ar" ? "15px" : "34px",
        top: localStorage.getItem("i18nextLng") === "ar" ? "-20px" : "-41px",
        minHeight: "42px",
        background: "#FF690F",
       border:'1px solid #FF690F'
      }}
    >
        <Box sx={{position: 'absolute',
   top:'-9px',
    minWidth: '11px',
    background: '#FF690F',
    borderRadius: '50px',
    minHeight: '11px',
    left: '-5px'}} > 

        </Box>
    </Box>
  );

  const connector_7 = (
    <>
        <Box
            sx={{
              position: "absolute",
              top: "-80px", // Adjusted to align with new child position
              left: "154px", // Adjusted to align with new child position
              minHeight: "42px", // Adjusted from parent
              background: "#FF690F", // Adjusted from parent
              border: "1px solid #FF690F", // Adjusted from parent
            }}
          />
        <Box
          sx={{
            position: "absolute",
            bottom: "77px", // Adjusted from child
            minWidth: "11px", // Adjusted from child
            background: "#FF690F",
            borderRadius: "50px", // Adjusted from child
            minHeight: "11px", // Adjusted from child
            left: "149px", // Adjusted from child
          }}
        >
            
          
        </Box>
        </>
      );
  
  // view sources button - connector
  const connector_5 = (
    <Box
      sx={{
        position: "absolute",
       
        left: localStorage.getItem("i18nextLng") === "ar" ? "" : "48px",
        right: localStorage.getItem("i18nextLng") === "ar" ? "335px" : "",
        top:'-109px',
        minHeight: "42px",
        background: "#FF690F",
       border:'1px solid #FF690F'
      }}
    >
        <Box sx={{position: 'absolute',
   bottom:'34px',
    minWidth: '11px',
    background: '#FF690F',
    borderRadius: '50px',
    minHeight: '11px',
    left: '-6px'}} > 

        </Box>
    </Box>
  );

  const containerElement = document.querySelector('.prompt-plus-container-chatai');
let adjustedPosition = ['50%', '50%']; // Default position if the element is not found

if (containerElement) {
  const rect = containerElement.getBoundingClientRect();
  adjustedPosition = [rect.left + 50, rect.top]; // Add 50 to the x-coordinate
}


export const interactiveTutorialAllSteps = {

   
"quick-setup-main-container": {
    id: "quick-setup-main-container",
    steps: [
        {
            selector: ".quick-start-setup", // CSS selector of the element
            content: (
                <>
                    <Box sx={{ position: 'absolute', bottom: '15px', left: '43px' }}>
                        {connector}
                    </Box>
                    <Typography sx={{ maxWidth: '390px' }}>
                        {tutorialLocalizer("welcome_to_simpla_tutorial")}
                    </Typography>
                </>
            ),
            position: 'top',
        },
    ],
},

"chatmode_selection": {
    id: "chatmode_selection",
    steps: [
        {
            selector: ".modes-container-wrapper", // CSS selector of the element
            content: (
                <>
               
                 <Box sx={{position:'absolute',bottom:'15px',left:'-47px'}} >
                   {connector}
                </Box>
                <Typography sx={{maxWidth:'390px'}}>Select a conversation </Typography>
                
                </>
                
            ),
                position:'top'
        },
        ]
    },
    "enter_query":{
        id:'enter_query',
        steps: [
            {
                selector: '.prompt-plus-container-chatai',
                content: (
                    <>
                        <Box sx={{ position: 'absolute', bottom: '-28px' }}>
                            {connector_2}
                        </Box>
                        <Typography sx={{ maxWidth: '390px' }}>
                            {tutorialLocalizer("ask_queries")}
                        </Typography>
                    </>
                ),
                position: 'bottom',
            },
            {
                selector: '.ButtonInput1', // Keep the spotlight on the container
                content: (
                    <>
                        <Box sx={{ position: 'absolute', bottom: '0px' }}>
                            {connector_3}
                        </Box>
                        <Typography sx={{ maxWidth: '390px' }}>
                            {tutorialLocalizer("click_submit")}
                        </Typography>
                    </>
                ),
                position: 'top',
            },
          ],
          
    },

    "signoff-icon":{
        id:'signoff-icon',
        steps:[
            {
                selector:'.signoff-icon',
                content:(
                    <>
                    <Box sx={{position:'absolute',bottom:'-9px',left:'-82px'}} >
                    {connector_2}
                 </Box>
                    <Typography sx={{maxWidth:'350px'}}>Click the Sign-Off button to see how you can validate Simpla’s advice with a human expert.</Typography>
                    </>
                ),
                position:'bottom'
                    
            }
        ]
},
"sign-off-toast-modal":{
    id:'sign-off-toast-modal',
    steps:[
        {
            selector:'.MuiPaper-rounded',
            content:(
                <>
         <Box sx={{position:'absolute',top:'67px',left:'35px'}} >
            {connector_12}
         </Box>
         <Typography sx={{maxWidth:'380px'}}>Awesome! Your advice has been sent for validation.</Typography>
         <Typography sx={{maxWidth:'380px'}}>Please check your email for the signed-off advice document.</Typography>
                </>
            ),
            position:'top'
        
        }
    ]
},

    "feedbackModalWrapper":{
            id:'feedbackModalWrapper',
            steps:[
                {
                    selector:'.feedbackModalWrapper',
                    content:(
                        <>
                    <Box sx={{ position: 'absolute', bottom: '15px', left: '-47px' }}>
                    {connector}
                    </Box>
                    <Typography sx={{ maxWidth: '390px' }}>
                        {tutorialLocalizer("select_conversation")}
                    </Typography>
                </>
            ),
            position: 'top',
        },
    ],
},

"enter_query": {
    id: 'enter_query',
    steps: [
        {
            selector: '.prompt-plus-container-chatai',
            content: (
                <>
                    <Box sx={{ position: 'absolute', bottom: '-28px' }}>
                        {connector_2}
                    </Box>
                    <Typography sx={{ maxWidth: '390px' }}>
                        {tutorialLocalizer("ask_queries")}
                    </Typography>
                </>
            ),
            position: 'bottom',
        },
        {
            selector: '.ButtonInput1', // Keep the spotlight on the container
            content: (
                <>
                    <Box sx={{ position: 'absolute', bottom: '0px' }}>
                        {connector_3}
                    </Box>
                    <Typography sx={{ maxWidth: '390px' }}>
                        {tutorialLocalizer("click_submit")}
                    </Typography>
                </>
            ),
            position: 'top',
        },
    ],
},


"feedbackModalWrapper": {
    id: 'feedbackModalWrapper',
    steps: [
        {
            selector: '.feedbackModalWrapper',
            content: (
                <>
                    <Box sx={{ position: 'absolute', bottom: '15px' }}>
                        {connector}
                    </Box>
                    <Typography sx={{ maxWidth: '340px' }}>{tutorialLocalizer("feedback_title")}</Typography>
                    <Typography sx={{ maxWidth: '340px' }}>{tutorialLocalizer("feedback_description")}</Typography>
                </>
            ),
            position: 'top'
        }
    ]
},

"desktop-profile-view": {
    id: 'desktop-profile-view',
    steps: [
        {
            selector: '.profile-view-menu',
            content: (
                <>
                    <Box sx={{ position: 'absolute', bottom: '12px' }}>
                        {connector_10}
                    </Box>
                    <Typography sx={{ maxWidth: '390px' }}>{tutorialLocalizer("desktop_profile_description")}</Typography>
                </>
            ),
            position: 'left'
        }
    ]
},

"mobile-profile-view": {
    id: 'mobile-profile-view',
    steps: [
        {
            selector: '.profile-view-menu',
            content: (
                <>
                    <Box sx={{ position: 'absolute', bottom: '12px' }}>
                        {connector_10}
                    </Box>
                    <Typography sx={{ maxWidth: '390px' }}>{tutorialLocalizer("mobile_profile_description")}</Typography>
                </>
            ),
            position: 'left'
        }
    ]
},

"view-sources": {
    id: 'view-sources',
    steps: [
        {
            selector: '.view-sources',
            content: (
                <>
                    <Box sx={{ position: 'absolute', bottom: '0px' }}>
                        {connector_5}
                    </Box>
                    <Typography sx={{ maxWidth: '390px' }}>{tutorialLocalizer("view_sources_content")}</Typography>
                </>
            ),
            position: 'bottom'
        }
    ]
},

 "chat-sources-container": {
    id: 'chat-sources-container',
    steps: [
        {
            selector: '.chat-sources-container',
            content: tutorialLocalizer("chat_sources_description")
        },
        {
            selector: '.pp-container',
            content: tutorialLocalizer("chat_sources_prompt_plus")
        },
    ]
},
"suggested-prompts": {
    id: 'suggested-prompts',
    steps: [
        {
            selector: '.opened-action-prompts',
            content: (
                <>
                    <Box sx={{ position: 'absolute', bottom: '0px', left: '-47px' }}>
                        {connector_9}
                    </Box>
                    <Typography sx={{ maxWidth: '390px' }}>{tutorialLocalizer("suggested_prompts_content1")}</Typography>
                    <Typography sx={{ maxWidth: '390px' }}>{tutorialLocalizer("suggested_prompts_content2")}</Typography>
                </>
            ),
            position: 'top'
        },
    ]
},
"suggested-prompts-icon": {
    id: 'suggested-prompts-icon',
    steps: [
        {
            selector: '.suggested-prompts-icon',
            content: tutorialLocalizer("suggested_prompts_icon_content")
        }
    ]
},
"action-prompts-div": {
    id: 'action-prompts-div',
    steps: [
        {
            selector: '.prompt-plus-container-chatai',
            content: (
                <>
                    <Box sx={{ position: 'absolute', top: '42px', left: '35px' }}>
                        {connector_12}
                    </Box>
                    <Typography sx={{ maxWidth: '390px' }}>{tutorialLocalizer("action_prompts_title")}</Typography>
                    <Typography sx={{ maxWidth: '390px' }}>{tutorialLocalizer("action_prompts_instruction")}</Typography>
                </>
            ),
            position: 'top',
            highlightedSelectors: ['.pp-container']
        }
    ]
},
"action-prompt-email": {
    id: 'action-prompt-email',
    steps: [
        {
            selector: '.pseudo-accordion-main',
            content: (
                <>
                    <Box sx={{ position: 'absolute', bottom: '0px', left: '-47px' }}>
                        {connector_9}
                    </Box>
                    <Typography sx={{ maxWidth: '390px' }}>{tutorialLocalizer("action_prompt_email_instruction")}</Typography>
                </>
            ),
            position: 'top'
        }
    ]
},

"action-prompt-email-steps": {
    id: 'action-prompt-email-steps',
    steps: [
        {
            selector: '.aim-field-email',
            content: (
                <>
                    <Box sx={{ position: 'absolute', top: '42px', left: '35px' }}>
                        {connector_12}
                    </Box>
                    <Typography sx={{ maxWidth: '390px' }}>
                        {tutorialLocalizer("action_prompt_email_steps")}
                    </Typography>
                </>
            ),
            position: 'top',
        },
        {
            selector: '.textbox-field',
            content: (
                <>
                    <Box sx={{ position: 'absolute', top: '42px', left: '35px' }}>
                        {connector_12}
                    </Box>
                    <Typography sx={{ maxWidth: '390px' }}>
                        {tutorialLocalizer("great_prefilled_email_topic")}
                    </Typography>
                </>
            ),
            position: 'top',
        },
        {
            selector: '.to-from-container-desktop',
            content: (
                <>
                    <Box sx={{ position: 'absolute', top: '42px', left: '35px' }}>
                        {connector_12}
                    </Box>
                    <Typography sx={{ maxWidth: '390px' }}>
                        {tutorialLocalizer("recipient_email_defaults")}
                    </Typography>
                </>
            ),
            position: 'top',
        },
        {
            selector: '.tone-slider-container',
            content: (
                <>
                    <Box sx={{ position: 'absolute', top: '67px', left: '35px' }}>
                        {connector_12}
                    </Box>
                    <Typography sx={{ maxWidth: '390px' }}>
                        {tutorialLocalizer("personalise_email_tone")}
                    </Typography>
                </>
            ),
            position: 'top',
        }
    ]
},
"generate-email-prompt": {
    id: 'generate-email-prompt',
    steps: [
        {
            selector: '.generate-prompt-button-custom-en',
            content: (
                <>
                    <Box sx={{ position: 'absolute', bottom: '0px', left: '-47px' }}>
                        {connector_9}
                    </Box>
                    <Typography sx={{ maxWidth: '390px' }}>
                        {tutorialLocalizer("generate_email_prompt")}
                    </Typography>
                </>
            ),
            position: 'top',
        }
    ]
},
"email-generate-response": {
    id: 'email-generate-response',
    steps: [
        {
            selector: '.chatLiveContent',
            content: (
                <>
                    <Box sx={{ position: 'absolute', top: '39px', left: '35px' }}>
                        {connector_7}
                    </Box>
                    <Typography sx={{ maxWidth: '390px' }}>
                        {tutorialLocalizer("simpla_generated_email")}
                    </Typography>
                </>
            ),
            position: 'bottom',
        }
    ]
},

   

"history-button": {
    id: 'history-button',
    steps: [
        {
            selector: '.history-button',
            content: (
                <>
                    <Box sx={{ position: 'absolute', top: '0px' }} >
                        {connector_4}
                    </Box>  
                    <Typography sx={{ maxWidth: '390px' }}>
                        {tutorialLocalizer("manage_past_conversations")}
                    </Typography>
                </>
            ),
            position: 'bottom',
        },
    ]
},
"history-chats": {
    id: 'history-chats',
    steps: [
        {
            selector: '.AiChats',
            content: (
                <>
                    <Box sx={{ position: 'absolute', top: '0px', left: '140px' }} >
                        {connector_4}
                    </Box>  
                    <Typography sx={{ maxWidth: '390px' }}>
                        {tutorialLocalizer("previous_conversations_stored")}
                    </Typography>
                </>
            ),
            position: 'bottom'
        },
        {
            selector: '.chat-toggle-icon',
            content: (
                <>
                    <Box sx={{ position: 'absolute', top: '41px', left: '-111px' }} >
                        {connector_7}
                    </Box>
                    <Typography sx={{ maxWidth: '390px' }}>
                        {tutorialLocalizer("mark_favourite_conversation")}
                    </Typography>
                </>
            )
        }
    ]
},
"mark-favourite": {
    id: 'mark-favourite',
    steps: [
        {
            selector: '.mark-favourite-icon',
            content: (
                <>
                    <Box sx={{ position: 'absolute', top: '42px', left: '-11px' }} >
                        {connector_7}
                    </Box>
                    <Typography sx={{ maxWidth: '390px' }}>
                        {tutorialLocalizer("click_icon_mark_favourite")}
                    </Typography>
                </>
            ),
            position: 'bottom'
        },
        {
            selector: '.history-search',
            content: (
                <>
                    <Box sx={{ position: 'absolute', top: '42px', left: '35px' }} >
                        {connector_7}
                    </Box>  
                    <Typography sx={{ maxWidth: '390px' }}>
                        {tutorialLocalizer("search_bar_locate_conversation")}
                    </Typography>
                </>
            ),
            position: 'bottom'
        },
        {
            selector: '.filterbtn',
            content: (
                <>
                    <Box sx={{ position: 'absolute', top: '42px', left: '-116px' }} >
                        {connector_7}
                    </Box> 
                    <Typography sx={{ maxWidth: '390px' }}>
                        {tutorialLocalizer("filter_marked_conversations")}
                    </Typography> 
                </>
            ),
            position: 'bottom'
        },
        {
            selector: '.help-button',
            content: (
                <>
                    <Box sx={{ position: 'absolute', top: '42px', left: '-97px' }} >
                        {connector_7}
                    </Box>  
                    <Typography sx={{ maxWidth: '390px' }}>
                        {tutorialLocalizer("simpla_help_tour")}
                    </Typography>
                </>
            ),
            position: 'bottom'
        },
    ]
},
"help-tutorial": {
    id: 'help-tutorial',
    steps: [
        {
            selector: '.vediobtn',
            content: (
                <>
                    <Box sx={{ position: 'absolute', bottom: '12px' }} >
                        {connector_11}
                    </Box> 
                    <Typography sx={{ maxWidth: '390px' }}>
                        {tutorialLocalizer("video_tutorial_description")}
                    </Typography>
                </>
            ),
            position: 'right'
        },
        {
            selector: '.chatbtn',
            content: (
                <>
                    <Box sx={{ position: 'absolute', bottom: '12px' }} >
                        {connector_11}
                    </Box> 
                    <Typography sx={{ maxWidth: '390px' }}>
                        {tutorialLocalizer("faq_description")}
                    </Typography>
                </>
            ),
            position: 'right'
        },
        {
            selector: '.tourbtn',
            content: (
                <>
                    <Box sx={{ position: 'absolute', bottom: '-5px' }} >
                        {connector_11}
                    </Box>
                    <Typography sx={{ maxWidth: '390px' }}>
                        {tutorialLocalizer("product_tour_description")}
                    </Typography>
                </>
            ),
            position: 'right'
        },
        {
            selector: '.add-btn',
            content: tutorialLocalizer("new_chat_explore")
        }
    ]
},
"new_chat": {
    id: 'new_chat',
    steps: [
        {
            selector: '.advise-btn',
            content: (
                <>
                    <Box sx={{ position: 'absolute', bottom: '-30px' }} >
                        {connector_11}
                    </Box>
                    <Typography sx={{ maxWidth: '390px' }}>
                        {tutorialLocalizer("advise_module_description")}
                    </Typography> 
                </>
            ),
            position: 'right'
        },
        {
            selector: '.upload',
            content: (
                <>
                    <Box sx={{ position: 'absolute', bottom: '-18px' }} >
                        {connector_11}
                    </Box> 
                    <Typography sx={{ maxWidth: '390px' }}>
                        {tutorialLocalizer("process_module_beta")}
                    </Typography>
                </>
            ),
            position: 'right'
        },
        {
            selector: '.tabler',
            content: (
                <>
                    <Box sx={{ position: 'absolute', bottom: '-18px' }} >
                        {connector_11}
                    </Box> 
                    <Typography sx={{ maxWidth: '390px' }}>
                        {tutorialLocalizer("analyse_module_description")}
                    </Typography>
                </>
            ),
            position: 'right'
        }
    ]
},
"tour_skipped": {
    id: 'tour_skipped',
    steps: [
        {
            selector: '.tourbtn-skipped',
            content: (
                <>
                    <Box sx={{ position: 'absolute', bottom: '-18px' }} >
                        {connector_11}
                    </Box>
                    <Typography sx={{ maxWidth: '390px' }}>
                        {tutorialLocalizer("tour_skipped_message")}
                    </Typography> 
                </>
            ),
            position: 'right'
        }
    ]
},
"profile-incomp": {
    id: 'profile-incomp',
    steps: [
        {
            selector: '.profile-incomp',
            content: (
                <>
                    <Box sx={{ position: 'absolute', bottom: '15px', left: '30px' }} >
                        {connector}
                    </Box>
                    <Typography sx={{ maxWidth: '390px' }}>
                        {tutorialLocalizer("profile_incomplete_message")}
                    </Typography>
                </>
            ),
            position: 'top'
        }
    ]
},
"heads-up": {
    id: 'heads-up',
    steps: [
        {
            selector: '.cross-icon',
            content: (
                <>
                    <Box sx={{ position: 'absolute', bottom: '15px', left: '-118px' }} >
                        {connector}
                    </Box>
                    <Typography sx={{ maxWidth: '390px' }}>
                        {tutorialLocalizer("click_close_message")}
                    </Typography>
                </>
            ),
            position: 'top'
        }
    ]
}

}

export const getCurrentSteps=(id)=>{

}

export const getTutorialStepsById = (id) => {
    const tutorial = interactiveTutorialAllSteps[id];
    return tutorial ? tutorial.steps : null;
};

const initialValue = {
step_active:'',
step_no:null
} 


const interactiveTutorialSlice = createSlice({
    name:'interactiveTutorialSteps',
    initialState: initialValue,
    reducers: {
        setInteractiveTutorialSteps: (state, action) => {

            state.step_active=action.payload.id;
            state.step_no=action.payload.no;   
        },
    }
})

export const {setInteractiveTutorialSteps} = interactiveTutorialSlice.actions;
export default interactiveTutorialSlice.reducer