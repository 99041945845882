import React, { createContext, useState, useMemo } from 'react';
import { useTracking } from 'react-tracking';
import { jwtDecode } from 'jwt-decode';
import { setProfileImage } from '../ReduxSlices/ProfileImageSlice';
import { useDispatch } from 'react-redux';
import { setApiCount } from '../ReduxSlices/ApiCountSlice';
import { setProfileCompleteModalState, setProfileCompletion, setProfileId } from '../ReduxSlices/ProfileCompletionSlice';
import { setSignOffCredits } from '../ReduxSlices/SignOffCreditsSlice';
import { setProfileEntity } from '../ReduxSlices/AccountSettingSlice';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const dispatch = useDispatch()
  const { trackEvent } = useTracking({ component: 'AuthContext' });
  const [token, setToken] = useState(localStorage.getItem('token') || null);

  async function tokenDecoder(user_token){
    try{
      if (user_token) {
        // Decode the token
        const decodedToken = jwtDecode(user_token);
  
        // Log or use the decoded token information
        //console.log('Decoded Token:', decodedToken);
      }          
      }
      catch(error){
        //console.log("Error while decoding token: " + error)
      }
  } 

  const login = (newToken) => {
    
     localStorage.setItem('token', newToken.access_token);
     localStorage.setItem('profile', newToken.has_profile);
     localStorage.setItem('onboarding', newToken.onboarding);
     
        let access_token = newToken.access_token

        tokenDecoder(access_token).then((res)=>{
          //console.log("token decode successfully",res?.data)
        }).catch((error)=>{
          //console.log("failed to decode token",error)
        })

  };


  const clearUser = () => {

    localStorage.removeItem('firstname');
    localStorage.removeItem('lastname');
    localStorage.removeItem('mode');
    localStorage.removeItem('token');
    localStorage.removeItem('access_token');
    localStorage.removeItem('profile');
    localStorage.removeItem('profileid');
    localStorage.removeItem('session_id');

    localStorage.removeItem('show-disclaimer');
    localStorage.removeItem('apiCounter');
    localStorage.removeItem('questioner_filled');
    localStorage.removeItem('device_info');
    localStorage.removeItem('onboarding');
    localStorage.removeItem('profile_country');
    localStorage.removeItem('user_ip');
    localStorage.removeItem('user_geo_data');
    dispatch(setApiCount("0"))
    dispatch(setProfileCompletion(false))
    dispatch(setProfileId(""))
    dispatch(setProfileCompleteModalState(false))
    dispatch(setSignOffCredits("0"))

    localStorage.clear()
    sessionStorage.clear()

    //localStorage.removeItem('i18nextLng');
    
    setToken(null);
  };

  const logout = () => {

    trackEvent({
      event_type: "click",
      event_name: "logout",
      event_data:{
          action: "user just logged out",        
      }
  })

    dispatch(setProfileImage(""))
    dispatch(setApiCount("0"))
    dispatch(setProfileCompletion(false))
    dispatch(setProfileId(""))
    dispatch(setProfileCompleteModalState(false))
    dispatch(setSignOffCredits("0"))
    dispatch(setProfileEntity({}))


    localStorage.removeItem('firstname');
    localStorage.removeItem('lastname');
    localStorage.removeItem('mode');
    localStorage.removeItem('token');
    localStorage.removeItem('access_token');
    localStorage.removeItem('profile');
    localStorage.removeItem('profileid');
    localStorage.removeItem('session_id');

    localStorage.removeItem('show-disclaimer');
    localStorage.removeItem('apiCounter');
    localStorage.removeItem('questioner_filled');
    localStorage.removeItem('device_info');
    localStorage.removeItem('onboarding');
    localStorage.removeItem('profile_country');
    localStorage.removeItem('user_ip');
    localStorage.removeItem('user_geo_data');

    localStorage.clear()
    sessionStorage.clear()
    //localStorage.removeItem('i18nextLng');
    setToken(null);
    
  };

  const authContextValue = useMemo(() => {
    return { token, login, logout, clearUser };
  }, [token, login, logout]);

  return (
    <AuthContext.Provider value={authContextValue}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
